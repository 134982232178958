import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Home() {
  const { t } = useTranslation();

  const title = 'Big Boss Generation';

  const navigate = useNavigate();
  // const videoRef = useRef(null);
  const sliderRef = useRef(null); // Ref to control the Slider
  /*
    useEffect(() => {
      const video = videoRef.current;
      if (video) {
        video.muted = true;
        video.play().catch((error) => {
          // Handle error if the video doesn't play, such as user interaction required
          console.error('Video playback failed:', error);
        });
      }
    }, []);
  */
  // Video durations in milliseconds
  const videoDurations = [26000, 12000]; // 26s for first video, 12s for second video

  // Slider configuration
  const settings = {
    dots: true, // Show navigation dots at the bottom
    infinite: true, // Enable infinite looping
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: false, // Disable built-in autoplay; we'll handle it manually
    pauseOnHover: false, // Do not pause on hover
    beforeChange: (current, next) => {
      // Pause the current video before sliding
      const videos = document.querySelectorAll('.slider-video');
      // videos[current].pause();
    },
    afterChange: (current) => {
      // Play the current video and set timeout for next slide
      const videos = document.querySelectorAll('.slider-video');
      const video = videos[current];
      // video.currentTime = 0; // Reset to start
      // video.muted = true; // Ensure the video is muted
      // video.play().catch((error) => console.error('Video playback failed:', error));

      // Move to next slide after the video duration
      const duration = videoDurations[current];
      setTimeout(() => {
        sliderRef.current?.slickNext(); // Move to next slide
      }, duration);
    },
  };

  // Initial play on mount
  useEffect(() => {
    const firstVideo = document.querySelector('.slider-video');
    if (firstVideo) {
      // firstVideo.muted = true;
      // firstVideo.play().catch((error) => console.error('Video playback failed:', error));
    }

    // Start the first timeout
    setTimeout(() => {
      sliderRef.current?.slickNext();
    }, videoDurations[0]);
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-fluid fade-in">
        <div className="px-4 py-0 text-center home-content">
          <div className="col-12 mx-auto pt-4">
            <div className='row fs-1 bg-tr-dark p-2 mt-5' style={{ height: window.innerWidth >= 640 ? '60vh' : ((window.innerWidth / 16) * 8) + 'px' }}>
              {/*
              <video controls autoPlay playsInline defaultMuted muted style={{ maxHeight: '100%', width: 'auto' }}
                className='mx-auto'>
                <source src='/images/BigBossVideo3_Short.mp4' type='video/mp4' />
              </video>
              */}
              <Slider ref={sliderRef} {...settings} style={{ height: '100%', width: '100%' }}>
                {/* First video */}
                <div style={{ height: '100%' }}>
                  <video
                    controls
                    autoPlay
                    playsInline
                    muted
                    defaultMuted
                    loop
                    className="slider-video mx-auto"
                    style={{ maxHeight: window.innerWidth >= 640 ? '60vh' : ((window.innerWidth / 16) * 7) + 'px', width: 'auto', objectFit: 'contain' }}
                  >
                    <source src="/images/BigBossVideo3_Short.mp4" type="video/mp4" />
                  </video>
                </div>
                {/* Second video - adjust path as needed */}
                <div style={{ height: '100%' }}>
                  <video
                    controls
                    autoPlay
                    playsInline
                    muted
                    defaultMuted
                    loop
                    className="slider-video mx-auto"
                    style={{ maxHeight: window.innerWidth >= 640 ? '60vh' : ((window.innerWidth / 18) * 7) + 'px', width: 'auto', objectFit: 'contain' }}
                  >
                    <source src="/images/BBoss_Promotion_20250330.mp4" type="video/mp4" />
                  </video>
                </div>
              </Slider>
            </div>
            <div className='row fs-1' style={{ height: window.innerWidth >= 640 ? '60vh' : '420px' }}>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-md-center align-self-end mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/about')}
                ><Trans i18nKey='home-about' /></div>
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-center mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/buy')}
                ><Trans i18nKey='home-apply' /></div>
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-md-center align-self-sm-start mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/contact')}
                >{t('home-contact')}</div>
              </div>
            </div>
            <div className='row fs-1 mt-0 mb-5 p-2 bg-tr-dark' style={{ height: 'auto' }}>
              <div className="col-12 justify-content-center p-3 pb-0 my-0">
                <h2 className='text-chaman'>{t('metaverse')}</h2>
                <p style={{ fontSize: window.innerWidth >= 640 ? '24px' : '16px', fontWeight: 'lighter' }}>
                  <Trans i18nKey='metaverse-text' />
                </p>
              </div>
              <div className="col-md-6 col-sm-12 d-flex justify-content-center mb-5">
                <a href='https://meta.bigbossgeneration.com/bigboss/' target='_blank'>
                  <img src='/images/Bboss_Metaverse-Webpage.gif' className='img-fluid rounded' />
                </a>
              </div>
              <div className="col-md-6 col-sm-12 d-flex justify-content-center mb-5">
                <a href='https://www.pgyer.com/EYYQ4qEk' target='_blank'>
                  <img src='/images/Bboss_Metaverse-App.gif' className='img-fluid rounded' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Home;
