import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import './btn.css';

function ComingEvent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [evts, setEvts] = useState([]);

  const getEvents = async () => {
    axios.get('/data/events.json?t=' + new Date().getTime())
      .then((res) => {
        setEvts(res.data)
        console.log(res.data)
      })
  }

  useEffect(() => {
    getEvents()
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('upcoming-events-title')}</title>
      </Helmet>
      <main className="container-fluid fade-in" id="about-main" >
        <div className="px-4 pt-5 mt-5 row">
          <h1 className="display-5 fw-bold py-4 text-chaman">{t('upcoming-events-title')}</h1>
        </div>
        <div className="px-4 pt-1 mt-2 row bg-tr-dark">
          <div className='text-center col-12 px-md-4 px-sm-1 py-4 rounded-3'>
            {t('upcoming-events-text')}
          </div>
          <div className='w-100' />
          {evts.map((event, index) => (
            <div key={index} className="col-12 col-md-3 px-md-4 px-sm-1 py-4">
              <div className="card h-100  bg-tr-dark">
                <img src={`/images/events/${event.thumbnail}`} className="card-img-top" alt={event.title} />
                <div className="card-body">
                  <h5 className="card-title">{event.title ?? ' -- '}</h5>
                  <p className="card-text">{event.description ?? ' -- '}</p>
                  <p className="card-text"><tt>{event.date ?? ' -- '}</tt></p>
                  <p className="card-text"><span className='badge bg-white text-dark'>{event.type ?? ' -- '}</span></p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-100" style={{ height: '498px' }}>&nbsp;</div>
      </main>
    </>
  );
}

export default ComingEvent;
