import { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import md5 from 'md5';

import {
  Col, Button, Form, FormControl, InputGroup, FormLabel, Spinner,
} from 'react-bootstrap';
import { emailPattern, namePattern } from '../common/constants';
import useAuth from '../hooks/useAuth';

import './signup.css';

const ENCRYPTED_MBR_REF = [
  'cc4d0e32672f528a85f881c5b4b02a48',
  'fe580934a624fd714d06836c98fff090',
  '9f4e8d89d948eb91f2abc78f37c99501',
  '25e5b25955b14dd1def1777992e80644',
  'aaa54f08fa572b9f9a07085e363516d5',
  '028955fcdae7c11e30fab8e984984ba3',
  '1fa11ab8e0765671054620e85b696169',
  'd23dc336544b5afb4677bb942bc8706e',
  '588b50254083ea2d3f10bda2901e09de',
  'a5473fc291cf0da956dafe7eb2988c35',
  'aba55d7a3602f5df36b2474909f97494',
  'd346d97239664b35860666f0b07a111a',
  'c44aaf3ff810433dd2b96f214cedfc46',
  'd4f73ed5f36e6c96be3be0aa493f6c1a',
  '0ba985bc32cfcdcefa155f7bea45139e',
  '6e0343f108a79666adc19a9fcfea653b',
  'f967689d4c59c99316aba2fec86d2fa2',
  'fa72629feb344667bebf35f0cd364151',
  'a37fd40ee77137406d57eec6c9c3914c',
  '90af981c6f1df9596172ecec705c6dea',
  '2289f358ee199f55cae88389b191be36',
];

function redirectPath(search) {
  const match = search.match(/redirect=(.*)/);
  const redirect = match?.[1];
  return redirect ? decodeURIComponent(redirect) : '/console';
}

function Signup() {
  const { t, i18n } = useTranslation();

  const title = t('signup-title');
  const context = useOutletContext();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { addUser, getSession } = useAuth();
  const [user, setUser] = useState(getSession())
  const navigate = useNavigate();
  const {
    register, handleSubmit, watch, formState: { errors }, setError,
  } = useForm();
  // Watch the value of the password field
  const password = watch('password');

  useEffect(() => {
    if (user) {
      navigate(redirectPath(search));
    }
  }, [])

  const handleSignup = async (data) => {
    try {
      setIsLoading(true);
      // Check if the mbr_ref is in the list of encrypted codes
      const mbrRefInput = data.mbr_ref || '';
      if (mbrRefInput) {
        const encryptedInput = md5(mbrRefInput);
        if (!ENCRYPTED_MBR_REF.includes(encryptedInput)) {
          setError('mbr_ref', { type: 'manual', message: t('signup-err-internal-code') });
          setIsLoading(false);
          return;
        }
      }

      const newUser = await addUser({ ...data, lang: i18n.language });
      // eslint-disable-next-line no-console
      console.log(`signup successful, user: ${newUser}`);
      context.setAlertOpts({
        text: 'Signup successful',
        icon: 'success',
        timer: 5000,
      });
      setIsLoading(false);
      navigate('/login');
    } catch (err) {
      context.setAlertOpts({
        text: err.message,
        icon: 'error',
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className='container-signup mt-5 pt-5'>
        <Form className='row g-2 card rounded-3 bg-tr-dark p-4' noValidate>
          <p className='fw-normal text-center'>
            <Trans i18nKey='signup-text'></Trans>
            </p>
          <Form.Group as={Col} lg='12' controlId='inputFullName'>
            <FormLabel>{t('signup-fullname')}</FormLabel>
            <FormControl type='text'
              isInvalid={errors.fullname}
              placeholder={t('signup-fullname')}
              {
              ...register('fullname', {
                required: true,
                pattern: namePattern,
              })
              }
            />
            <Form.Control.Feedback type='invalid'>
              {errors.fullname?.type === 'required' && t('signup-err-01')}
              {errors.fullname?.type === 'pattern' && t('signup-err-02')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg='12' controlId='inputEmail'>
            <FormLabel>{t('signup-email')}</FormLabel>
            <FormControl type='email'
              isInvalid={errors.email}
              placeholder='Email@domain.com'
              {
              ...register('email', {
                required: true,
                pattern: emailPattern,
              })
              }
            />
            <Form.Control.Feedback type='invalid'>
              {errors.email?.type === 'required' && t('signup-err-03')}
              {errors.email?.type === 'pattern' && t('signup-err-04')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md='12' controlId='inputPhone'>
            <Form.Label>{t('signup-phoneno')}</Form.Label>
            <Form.Control type='text'
              isInvalid={errors.phone}
              placeholder={t('signup-phoneno')}
              {...register('phone', { required: true })}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.phone && t('signup-err-05')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg='12' controlId='inputPassword'>
            <FormLabel>{t('signup-password')}</FormLabel>
            <FormControl type='password'
              isInvalid={errors.password}
              placeholder={t('signup-password')}
              {
              ...register('password', {
                required: true,
                minLength: 8,
              })
              }
            />
            <Form.Control.Feedback type='invalid'>
              {errors.password?.type === 'required' && t('signup-err-06')}
              {errors.password?.type === 'pattern' && t('signup-err-07')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg='12' controlId='inputConfirmPassword'>
            <FormLabel>{t('tp-confirm-purchase-btn')} {t('signup-password')}</FormLabel>
            <FormControl type='password'
              isInvalid={errors.confirmPassword}
              placeholder={`${t('tp-confirm-purchase-btn')} ${t('signup-password')}`}
              {
              ...register('confirmPassword', {
                required: true,
                minLength: 8,
                validate: (value) => (
                  value === password || t('signup-err-08')
                ),
              })
              }
            />
            <Form.Control.Feedback type='invalid'>
              {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg='12' controlId='inputMbrRef'>
            <Form.Label>{t('signup-internal-code')}</Form.Label>
            <Form.Control
              type='text'
              isInvalid={errors.mbr_ref}
              {...register('mbr_ref', { required: false })}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.mbr_ref && <span>{errors.mbr_ref.message}</span>}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mt-3'>
            <Form.Check
              label={t('su-tnc-06')}
              feedback={t('signup-err-09')}
              feedbackType='invalid'
              isInvalid={errors.agree}
              {...register('agree', { required: true })}
            />
          </Form.Group>

          <Form.Group className='my-3'>
            <Form.Check
              label={<>{t('su-tnc-01')}&nbsp;
                <a href='/tnc/Terms-of-Use' target='_blank'>{t('su-tnc-02')}</a>,&nbsp;
                <a href='/tnc/Disclaimer' target='_blank'>{t('su-tnc-03')}</a>{t('su-tnc-04')}&nbsp;
                <a href='/tnc/Privacy-Policy' target='_blank'>{t('su-tnc-05')}</a>.</>}
              feedback={t('signup-err-09')}
              feedbackType='invalid'
              isInvalid={errors.agreeTnC}
              {...register('agreeTnC', { required: true })}
            />
          </Form.Group>
          <Button className='w-100 btn btn-lg btn-primary'
            type='button'
            disabled={isLoading}
            onClick={handleSubmit(handleSignup)}
          >
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' hidden={!isLoading} />
            <span className='px-2'>{t('signup-button')}</span>
          </Button>
        </Form>
      </main>
    </>
  );
}

export default Signup;
